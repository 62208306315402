import React from 'react';
import { Carousel, CarouselItem } from 'react-bootstrap';
import styled from 'styled-components';
import '../css/HomeCarousel.css';

import slide01 from '../assets/sunrisenog.jpg';
import slide02 from '../assets/solargrid.jpg';
import slide03 from '../assets/cadnium2.jpg';
import slide04 from '../assets/ourpanel.jpg';

const Styles = styled.div`

`;

export const HomeCarousel = () => (
    <Styles>
        <div align="center"><Carousel fluid className="" controls={true} indicators={true} interval={4000} fade>
            <CarouselItem>
                <img className="d-block w-100" src={slide01} alt="Slide 1: Horsetooth Sunrise" />
                <div className="carousel-caption"><h3>The New Horizons in Photovoltaics - Installations &amp; Fabrication</h3></div>
            </CarouselItem>
            <CarouselItem>
                <img className="d-block w-100" src={slide04} alt="Slide 2: Falcon 9 Launch" />
                <div className="carousel-caption" style={{textShadow:'-1px 0 black'}}><h3>Simplest Solutions for the Most Complex Problems</h3></div>
            </CarouselItem>
            <CarouselItem>
                <img className="d-block w-100" src={slide03} alt="Slide 3: Submarine" />
                <div className="carousel-caption" style={{textShadow:'-1px 0 black'}}><h3>Research and Development by World Renowned Professionals</h3></div>
            </CarouselItem>
        </Carousel></div>
    </Styles>
)