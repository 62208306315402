import React from 'react';
import "./css/Compadv.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Image from 'react-bootstrap/Image';
import { Container, Row, Col } from 'react-bootstrap';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import Table from 'react-bootstrap/Table';
import { HashLink } from "react-router-hash-link";

import vschart from './assets/SilvsCDTE.png';
import linegraph from './assets/linegraph.png';
import graph from './assets/graph.png';
import linegraph2 from './assets/linegraph2.png';
import linegraph3 from './assets/linegraph3.png';
import legend from './assets/legend.png';
import processinput from './assets/processinput.png';
import DFTmodel from './assets/DFTmodel.png';
import diagramComp from './assets/diagramComp.png';
import area1 from './assets/area1.png';
import area2 from './assets/area2.png';
import smoke from './assets/smoke.png';
import needleClose from './assets/needleClose.png';
import homePanel from './assets/homepanel.png';
import linegraph4 from './assets/linegraph4.png';
import linegraph5 from './assets/linegraph5.png';
import panelcomparison from './assets/panelcomparison.png';

function Compadv() {
    return (
        <React.Fragment>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Competitive Advantage</Breadcrumb.Item>
                </Breadcrumb>
                <hr />
                {/*<a href="_blank" color="#2d419a">Scroll To...</a>
                <ul className="unList">
                    <li>Field Installations</li>
                    <li>Device Fabrication <br />
                        and Panel <br />
                        Manufacturing</li>
                    <li>Field Installations</li>
                    <li>Field Installations</li>
                </ul>
                <button onClick={executeScroll}> Click to scroll </button> */}
                <Row>
                    <Col id="install">
                    <h3 style={{paddingBottom: '10px'}}><span className="underline underlineBlue">Field Installations</span></h3>
                    Our proprietary panel installations provide excellent competitive and commercial advantage to the US energy industry. While the industry is attempting to identify and exploit every possible advantage to ensure the U.S. energy and national security, our approach provides an excellent opportunity. 
                    <br /><br />
                    We developed and installed a small setup to test the behavior of our method in actual field conditions. Results show over 2x power production in best case and more than 60% cumulative over a 21-day continuous period. Further optimization will extend this advantage beyond 2x.  
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                    <h3 style={{paddingBottom: '10px'}}><span className="underline underlineBlue">Device Fabrication and Panel Manufacturing</span></h3>
                    <Container>
                        <Row style={{paddingLeft: '30px'}}>
                            <Col>
                            <h4 style={{paddingBottom: '10px'}}><span className="underline underlineYellow">Why CdTe?</span></h4>
                            Owing to its lower temperature coefficient, CdTe inherently performs better than c-Si solar panels under hot and humid conditions. Moreover, being a direct band-gap material, CdTe performs better under diffused light conditions as well. This allows better power production from dawn to dusk with substantially better performance in overcast weather. 
                            <br /><br />
                            We have tested identical installed capacity of CdTe and top-of-the-line c-Si panels and convincingly seen CdTe outperform c-Si panels by nearly 20% power output under 3 different installation conditions – ground, floating, and rooftop.
                            <br /><br /><br />
                            <div className="tableDiv">
                            <Table striped bordered hover responsive="sm" className="center" style= {{ whiteSpace: 'unset' }} >
                                <thead>
                                    <tr>
                                    <th colSpan="7" style={{textAlign: 'center', background: '#C8C8C8'}}>
                                    Polycrystalline Silicon v. Thin-Film CdTe A
                                    </th>
                                    </tr>
                                    <tr>
                                    <th>Month</th>
                                    <th colSpan="3">Polycrystalline Silicon</th>
                                    <th colSpan="3">Thin-film CdTe</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td></td>
                                    <td>Rooftop</td>
                                    <td>Ground</td>
                                    <td>Floating</td>
                                    <td>Rooftop</td>
                                    <td>Ground</td>
                                    <td>Floating</td>
                                    </tr>
                                    <tr>
                                    <td>December</td>
                                    <td>82.23</td>
                                    <td>80.06</td>
                                    <td>83.93</td>
                                    <td>96.44</td>
                                    <td>95.87</td>
                                    <td>97.37</td>
                                    </tr>
                                    <tr>
                                    <td>January</td>
                                    <td>79</td>
                                    <td>81</td>
                                    <td>82</td>
                                    <td>93.46</td>
                                    <td>95.61</td>
                                    <td>96.65</td>
                                    </tr>
                                    <tr>
                                    <td>February</td>
                                    <td>81.50</td>
                                    <td>82.30</td>
                                    <td>82.56</td>
                                    <td>94.44</td>
                                    <td>96.32</td>
                                    <td>97.30</td>
                                    </tr>
                                </tbody>
                                </Table>
                                <br />
                            <Table bordered hover responsive="sm" className="center">
                                <thead>
                                    <tr>
                                    <th colSpan="7" style={{textAlign: 'center', background: '#C8C8C8'}}>
                                    Polycrystalline Silicon v. Thin-Film CdTe A
                                    </th>
                                    </tr>
                                    <tr>
                                    <th>Installation Condition</th>
                                    <th>Panel Type</th>
                                    <th>Power Generation (kWh)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td rowSpan="2">Greenhouse rooftop</td>
                                    <td>Polycrystalline Si</td>
                                    <td>17.1</td>
                                    </tr>
                                    <tr>
                                    <td>Thin-film CdTe</td>
                                    <td>20.4</td>
                                    </tr>
                                    <tr>
                                    <td rowSpan="2">Ground Installation</td>
                                    <td>Polycrystalline Si</td>
                                    <td>14.1</td>
                                    </tr>
                                    <tr>
                                    <td>Thin-film CdTe</td>
                                    <td>19.4</td>
                                    </tr>
                                    <tr>
                                    <td rowSpan="2">Floating</td>
                                    <td>Polycrystalline Si</td>
                                    <td>17.5</td>
                                    </tr>
                                    <tr>
                                    <td>Thin-film CdTe</td>
                                    <td>20.8</td>
                                    </tr>
                                    
                                </tbody>
                            </Table>
                            </div>
                            <div align="center"><img src={linegraph2} width="120%" rounded className="mb-3 mt-3 p-2 mb-4 bg-white rounded" /></div>
                            <h4 style={{paddingBottom: '10px'}}><span className="underline underlineYellow">JPHB Expertise in CdTe Thin Film</span></h4>
                            JPHB is founded by researchers that have been involved with this technology from its days of infancy. The company is led by Dr. Munshi who has over 10 years of hands-on experience in solar cell fabrication, process engineering, and vaccum systems. 
                            <br /><br />
                            Working with a dedicated team of scientists and engineers, they have developed and demonstrated some of the best performing solar cells in the world. Between 2010 and 2020, the team advanced the power conversion efficiency from a modest 10% to currently over 20%. The projections aim at substantially higher efficiencies over the next 3-5 years.
                            <br /><br />
                            <div align="center"><img src={linegraph3} width="75%" rounded className="mb-3 mt-3 p-2 mb-4 bg-white rounded" /></div>
                            <div align="center"><img src={legend} width="50%" rounded className="mb-5 rounded" style={{paddingTop: '0px'}} /></div>
                            </Col>
                        </Row>
                    </Container>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col id="scalability">
                    <h3 style={{paddingBottom: '10px'}}><span className="underline underlineBlue"  id='example-destination' name='example-destination'>Scalability and Repeatability</span></h3>
                    Over the past few years, the team has fabricated over 300,000 small area solar cells to identify groundbreaking innovations, implementations, and optimize the solar cell performance. Simultaneously, the team has maintained a clear line of sight to large-volume manufacturing and hence all innovations demonstrated have used scalable and repeatable fabrication methods. 
                    <br /><br />
                    Furthermore, JPHB understands the necessity of maintaining statistical control over the process to ensure every cell fabricated performs up to or better than the set standards. Moreover, the process control and performance parameters are diligently recorded to utilize them for further statistical optimization.
                    <div align="center"><img src={processinput} width="75%" rounded className="mb-3 mt-3 p-2 mb-4 bg-white rounded" /></div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col id="research">
                    <h3 style={{paddingBottom: '10px'}}><span className="underline underlineBlue"> End-To-End Ecosystem Sustainable Energy Solutions</span></h3>
                    JPHB team and our technology partners have pursued research at the cutting edge of CdTe solar and thin film technology spanning most of the defined <a style={{color: "blue", textDecoration: "underline"}} href="https://www.nasa.gov/directorates/heo/scan/engineering/technology/technology_readiness_level">technology readiness levels.</a> 
                    <br /> <br />
                    <Container>
                        <Row  style={{paddingLeft: '30px'}}> 
                            <Col>
                                <h4 style={{paddingBottom: '10px'}}><span className="underline underlineYellow">1. Basic Principles</span></h4>
                                Utilizing state-of-the-art first principles computational simulation methods to identify the influence of atomic structures on solar cell performance.
                                <div align="center"><img src={DFTmodel} width="75%" rounded className="mb-3 mt-3 p-2 mb-4 bg-white rounded" /></div>
                            </Col>
                        </Row>
                        <Row style={{paddingLeft: '30px'}}>
                            <Col>
                                <h4 style={{paddingBottom: '10px'}}><span className="underline underlineYellow">2. Technology Concept/Application Formulation</span></h4>
                                Converging classical physical models to advanced computational simulations and reliable experimental measurements to accelerate technology enhancement.
                                <div align="center"><img src={diagramComp} width="100%" rounded className="mb-3 mt-3 p-2 mb-4 bg-white rounded" /></div>
                            </Col>
                        </Row>
                        <Row style={{paddingLeft: '30px'}}>
                            <Col>
                                <h4 style={{paddingBottom: '10px'}}><span className="underline underlineYellow">3. Analytical and Experimental Proof of Concept</span></h4>
                                Using advanced materials characterization to accurately establish process-performance-property relationship. 
                            </Col>
                        </Row>
                        <Row style={{paddingLeft: '30px'}}>
                            <Col>
                            <div align="center"><img src={area1} width="75%" rounded className="mb-3 mt-3 p-2 mb-4 bg-white rounded" /></div>
                            </Col>
                            <Col>
                            <div align="center"><img src={area2} width="75%" rounded className="mb-3 mt-3 p-2 mb-4 bg-white rounded" /></div>
                            </Col>
                        </Row>
                        <Row style={{paddingLeft: '30px'}}>
                            <Col>
                                <h4 style={{paddingBottom: '10px'}}><span className="underline underlineYellow">4. Component Validation</span></h4>
                                Development and demonstration of necessary hardware components to develop and optimize next generation of low-cost high-efficiency solar cells.
                                <div align="center"><img src={smoke} width="50%" rounded className="mb-3 mt-3 p-2 mb-4 bg-white rounded" /></div>
                            </Col>
                        </Row>
                        <Row style={{paddingLeft: '30px'}}>
                            <Col>
                                <h4 style={{paddingBottom: '10px'}}><span className="underline underlineYellow">5. Component Validation in Application Environment</span></h4>
                                Using ISO-certified solar simulators and other industry-validated testing methods to characterize component and system-level performance.
                                <div align="center"><img src={needleClose} style={{maxWidth: '100%', maxHeight: "40%"}} rounded className="mb-3 mt-3 p-2 mb-4 bg-white rounded" /></div>
                            </Col>
                        </Row>
                        <Row style={{paddingLeft: '30px'}}>
                            <Col>
                                <h4 style={{paddingBottom: '10px'}}><span className="underline underlineYellow">6. Prototyping</span></h4>
                                Scaling small-area solar cells to fully functional solar mini-modules.
                                <div align="center"><img src={homePanel} style={{maxWidth: '40%', maxHeight: '10%'}} rounded className="mb-3 mt-3 p-2 mb-4 bg-white rounded" /></div>
                            </Col>
                        </Row>
                        <Row style={{paddingLeft: '30px'}}>
                            <Col>
                                <h4 style={{paddingBottom: '10px'}}><span className="underline underlineYellow">7. Prototype Validation in Application Environment</span></h4>
                                Conceptualized panel installation design prototyped and tested in application environment; minimum anticipated performance advantage demonstrated.
                                <div align="center"><img src={linegraph4} width="75%" rounded className="mb-3 mt-3 p-2 mb-4 bg-white rounded" /></div>
                            </Col>
                        </Row>
                        <Row style={{paddingLeft: '30px'}}>
                            <Col>
                                <h4 style={{paddingBottom: '10px'}}><span className="underline underlineYellow">8. Analytical and Experimental Proof of Concept</span></h4>
                                Consistent power generation advantage recorded over extended period.
                                <div align="center"><img src={linegraph5} width="75%" rounded className="mb-3 mt-3 p-2 mb-4 bg-white rounded" /></div>
                            </Col>
                        </Row>
                        <Row style={{paddingLeft: '30px'}}>
                            <Col>
                                <h4 style={{paddingBottom: '10px'}}><span className="underline underlineYellow">9. Actual System Test Through Mission Application</span></h4>
                                Scale representative system-level study for various application scenarios.
                                <div align="center"><img src={panelcomparison} width="80%" rounded className="mb-3 mt-3 p-2 mb-4 bg-white rounded" /></div>
                            </Col>
                        </Row>
                    </Container>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                    <h3 id="div" style={{paddingBottom: '10px'}}><span className="underline underlineBlue">Is CdTe Safe?</span></h3>
                    About 5 million CdTe solar panels are currently installed worldwide. They are certified for utility, community, and residential installations. 
                    <br /><br />
                    Since CdTe alloy is chemically different from elemental cadmium, it is safe to handle and poses minimal health and environmental risk. This is similar to sodium chloride. Sodium is explosive and chlorine is poisonous, but sodium chloride (table salt) is an essential part of human diet.
                    <br /><br />
                    The panels installed are completely sealed using industry and government validated encapsulation methods that make panels with this technology completely safe.  
                    </Col>
                </Row>
            </Container>
<br />
        </React.Fragment>
    );
}

export default Compadv;