import React from 'react';
import "./css/About.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap';
import {Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle} from 'reactstrap';

import amiths from './assets/amiths2.png';
import anthonyhs from './assets/anthonyhs.jpg';
import akashhs from './assets/akashhs.JPG';
import zachhs from './assets/zach.JPG';
import willhs from './assets/willhs.jpeg';
import jayhs from './assets/jayhs.png';
import sampathhs from './assets/sampathhs.png';
import tfdLogo from './assets/TFD.png';
import mdgreenenergy from './assets/mdgreenenergypartner.png';
import slide01 from './assets/sunrisenog.jpg';
import { render } from '@testing-library/react';

class About extends React.Component {
    state = { showingAmit: false, showingAnthony: false, showingAkash: false, showingZach: false, showingWill: false, showingJay: false, showingSampath: false };
    render(){
        const { showingAmit, showingAnthony, showingAkash, showingZach, showingWill, showingJay, showingSampath } = this.state;
        return (
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>About Us</Breadcrumb.Item>
                </Breadcrumb>
                <hr />
                <Row>
                    <Col>
                        <div class="about-image-block-container right-overlap">
                            <img src={slide01} className="about-content-img" alt="about_us" />
                        <div className="about-outer">
                            <div class="about-text-block">
                                <h2>Our Vision</h2>
                                <hr className="visionHr" /><br />
                                At <b>JPHB</b>, we believe that solutions to the biggest and the most complex problems must be simple. Only a simple solution can be executed at a scale that can make an impact for the betterment of the human society and world. 
                                <br /><br />
                                Our vision is to identify and implement disruptive yet simple engineering innovations that address some of the most challenging energy problems, thus making them sustainable, economically viable, and provide the U.S. energy industry a substantial competitive advantage. 
                                <br /><br /><sub>Learn more about how we live out this vision <a href="/#/Investors" style={{color: "blue"}}>here.</a></sub>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <br /><br />
                <Row className="rowAbout">
                    <Col className="visionContainer"  sm="12" md={{ size: 6 }}>
                        <br />
                    <div>
                        <h2>Our Team</h2>
                        <hr className="visionHr" />
                    </div>
                    </Col>
                </Row>
                <Row className="rowAbout">
                    <Col xs={12} sm={{span: 6}}>
                        <Card>
                            <CardImg top src={amiths} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                            <CardBody>
                            <CardTitle tag="h5">Amit Munshi</CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">Founder</CardSubtitle>
                            <hr />
                            <CardText>
                            Dr. Amit Haren Munshi has pursued state-of-the-art solar energy and materials engineering research for over 10 years and has been one of the most successful researchers in thin film photovoltaics. A recipient of several international academic awards, Dr. Munshi has been key to some of the key innovations in his area of expertise. Dr. Munshi has led research scale solar power conversion efficiency of CdTe thin film photovoltaics from 11% to over 20% over his academic research career. He and his team of motivated professionals have been key to introduction of several new processes and materials families to solar PV research. With a successful academic career, Dr. Munshi has now embarked on an entrepreneurial journey to take cutting-edge solutions from academic laboratory to commercial space. His vision – To make solar energy the obvious choice through technological, financial, functional, and entrepreneurial innovation.     
                            <span style={{ display: (showingAmit ? 'block' : 'none') }}>
                            <br />It is Dr. Munshi’s firm conviction that any invention or innovation, no matter how great, has little value unless it makes its way out of the laboratories and into the real world. Following this philosophy, Dr. Munshi founded JPHB to apply his knowledge and experience to solve problems that matter. At the same time, Dr. Munshi understands that economic viability of energy solutions is critical since the problem of this magnitude must be self-sustained for deployment and growth.
                            </span>
                            <a href="javascript:void(0);" onClick={() => this.setState({ showingAmit: !showingAmit })} style={{ display: (showingAmit ? 'none' : 'block'), color: "#2d419a", paddingTop: "10px"}}>Read More...</a>
                            <a href="javascript:void(0);" onClick={() => this.setState({ showingAmit: !showingAmit })} style={{ display: (showingAmit ? 'block' : 'none'), color: "#2d419a", paddingTop: "10px"}}>Hide...</a>
                            </CardText>
                            <a href="https://scholar.google.com/citations?user=aQ2ulaYAAAAJ" style={{color: "#2d419a"}}>Publications &gt;</a><br />
                            <a href="https://www.linkedin.com/in/amit-munshi/" style={{color: "#2d419a"}} target="_blank">Linkedin &gt;</a>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} sm={{span: 6}}>
                        <Card>
                            <CardImg top src={akashhs} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                            <CardBody>
                            <CardTitle tag="h5">Akash Shah</CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">Co-founder</CardSubtitle>
                            <hr />
                            <CardText>
                            Akash has a master’s degree in Materials Science from the prestigious Indian Institute of Technology and is current pursuing his doctoral degree in Mechanical Engineering specializing in advanced computational simulations and process engineering of thin film photovoltaics. Starting very early in his academic journey, Akash has published some of the most innovative and impactful research in high impact journals showing his caliber as a scientist and an engineer. 
                            <span style={{ display: (showingAkash ? 'block' : 'none') }}>
                            <br />
                            Akash has worked with Dr. Munshi for the past three years and Dr. Munshi often describes him as not a work horse but a work elephant. He has constantly demonstrated that he can smart for long hours tirelessly with utmost diligence and sincerity. He has been a great asset to Dr. Munshi’s research team and continues to bring the same value to his role at JPHB.   
                            </span>
                            <a href="javascript:void(0);" onClick={() => this.setState({ showingAkash: !showingAkash })} style={{ display: (showingAkash ? 'none' : 'block'), color: "#2d419a", paddingTop: "10px"}}>Read More...</a>
                            <a href="javascript:void(0);" onClick={() => this.setState({ showingAkash: !showingAkash })} style={{ display: (showingAkash ? 'block' : 'none'), color: "#2d419a", paddingTop: "10px"}}>Hide...</a>
                            </CardText>
                            <a href="https://www.linkedin.com/in/akashshah5432/" style={{color: "#2d419a"}} target="_blank">Linkedin &gt;</a>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="rowAbout">
                   
                    <Col xs={12} sm={{span: 6}}>
                    <Card>
                            <CardImg top src={zachhs} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                            <CardBody>
                            <CardTitle tag="h5">Zach Lustig</CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">Co-founder</CardSubtitle>
                            <hr />
                            <CardText>Zach has been part of JPHB since before the idea and layout of the organization was even laid out. He has been involved in discussions over various aspects of sustainable energy solutions with other members of the JPHB team. Zach comes with valuable hands-on experience in mechanical engineering and in recent months has rapidly picked up new skills in thin film processing and high-tech vacuum systems operation. 
                            <span style={{ display: (showingZach ? 'block' : 'none') }}>
                            <br />
                            Zach is a free-spirited engineer that flourishes when provided with an environment of creative freedom. He has shown strong appetite for knowledge and often asks the most brilliant questions that can catch even the best of the engineers off guard. This spirit of Zach forms a critical part of the JPHB founding team. Zach has also worked on small scale solar projects for off-grid applications. More recently, Zach has been developing his skills towards statistical data analysis to identify subtle trends in experimental data that can lead to small but very impactful changes to engineering processes. 
                            </span>
                            <a href="javascript:void(0);" onClick={() => this.setState({ showingZach: !showingZach })} style={{ display: (showingZach ? 'none' : 'block'), color: "#2d419a", paddingTop: "10px"}}>Read More...</a>
                            <a href="javascript:void(0);" onClick={() => this.setState({ showingZach: !showingZach })} style={{ display: (showingZach ? 'block' : 'none'), color: "#2d419a", paddingTop: "10px"}}>Hide...</a>
                            </CardText>
                            <a href="https://www.linkedin.com/in/zachlustig/" style={{color: "#2d419a"}} target="_blank">Linkedin &gt;</a>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} sm={{span: 6}}>
                        <Card>
                            <CardImg top src={willhs} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                            <CardBody>
                            <CardTitle tag="h5">Will Nichols</CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">Co-founder</CardSubtitle>
                            <hr />
                            <CardText>
                            Will has a dual bachelor’s degree in both Computer Science and Finance. He brings a wide experience background including business IT solutions, web design, and financial analysis. This background provides a wide encompassing mindset to bring crucial software solutions to JPHB ranging from engineering support to business management and financial and analysis.
                            <span style={{ display: (showingWill ? 'block' : 'none') }}>
                            <br />
                            Will's experience and passion stems from entrepreneurs throughout his family and through his own pursuits of in other realms such as facility management and personal training. Throughout his academic career he pursed many different passions and positions including IT management, web design, facility management, and even personal training, which gave him both the personal skills to build client relationships as well as opportunities to problem solve and engineer IT solutions in a business environment.
                            </span>
                            <a href="javascript:void(0);" onClick={() => this.setState({ showingWill: !showingWill })} style={{ display: (showingWill ? 'none' : 'block'), color: "#2d419a", paddingTop: "10px"}}>Read More...</a>
                            <a href="javascript:void(0);" onClick={() => this.setState({ showingWill: !showingWill })} style={{ display: (showingWill ? 'block' : 'none'), color: "#2d419a", paddingTop: "10px"}}>Hide...</a>
                            </CardText>
                            <a href="https://www.linkedin.com/in/william-nichols-699b50157/" style={{color: "#2d419a"}} target="_blank">Linkedin &gt;</a>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="rowAbout">
                    <Col xs={12} sm={{span: 6, offset: 3}}>
                        <Card>
                            <CardImg top src={anthonyhs} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                            <CardBody>
                            <CardTitle tag="h5">Anthony Nicholson</CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">Co-founder</CardSubtitle>
                            <hr />
                            <CardText>
                            Dr. Anthony Pino Nicholson earned his doctorate in Mechanical Engineering from Colorado State University with a focus on computational-based materials research for thin-film photovoltaics and manufacturing processes. His area of expertise spans a wide spectrum including Computational Fluid Dynamics, Finite Element Methods, Density Functional Theory, and Numerical Device Simulations.
                            <span style={{ display: (showingAnthony ? 'block' : 'none'), whiteSpace: 'no-wrap'}}>
                            <br />
                            He has demonstrated a strong basis in the subject matter related to photovoltaics with an emphasis toward improving the fundamental understanding of the technology. Dr. Nicholson is currently serving as a postdoctoral fellow at the National Renewable Energy Laboratory and aspires to expand the scope as well as impact of his research. Through his fellowship position and association with JPHB, Dr. Nicholson aspires to make a strong impact in the synergistic intersection of solar technology, education, social empowerment, and sustainable energy development.
                            <br /><br />
                            Dr. Nicholson is an enrolled member of the Diné, or the Navajo Nation. He seeks to empower Native American/Indigenous communities across the country starting with the Navajo Mountain (Naatsis'áán) and Alamo (T'iis Tsoh) Navajo Reservation regions. Dr. Nicholson has been instrumental in JPHB's ongoing project with Navajo Nation to assess the feasibility of off-grid systems for local indigenous families aimed at improving the standard of living in their communities. Vision and support provided by Dr. Nicholson and his family has laid the foundation for the JPHB team to make an effort towards providing these communities with a self-sustained and economical energy solution.
                            </span>
                            <a href="javascript:void(0);" onClick={() => this.setState({ showingAnthony: !showingAnthony })} style={{ display: (showingAnthony ? 'none' : 'block'), color: "#2d419a", paddingTop: "10px"}}>Read More...</a>
                            <a href="javascript:void(0);" onClick={() => this.setState({ showingAnthony: !showingAnthony })} style={{ display: (showingAnthony ? 'block' : 'none'), color: "#2d419a", paddingTop: "10px"}}>Hide...</a>
                            </CardText>
                            <a href="https://www.linkedin.com/in/anthony-nicholson-3231921b/" style={{color: "#2d419a"}} target="_blank">Linkedin &gt;</a>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="rowAbout">
                    <Col className="visionContainer"  sm="12" md={{ size: 6 }}>
                        <br />
                    <div>
                        <h2>Advisory Board
                        </h2>
                        <hr className="visionHr" />
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={{span: 6}}>
                        <Card>
                            <CardImg top src={jayhs} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                            <CardBody>
                            <CardTitle tag="h5">Jay Munshi</CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted"></CardSubtitle>
                            <hr />
                            <CardText>
                            Jay has a demonstrated expertise in Data Science and Databases since mid-1990s and leads Database Management department for IPSoft Inc. He is a senior manager responsible for deliverables for fortune 1000 clients and his organizational team spans across North America, Europe, and South Asia. Jay along with his team uses Data Analysis tools to employ best practices in Technology Support to roll out Robotic Automation. Through his expertise, Jay has led a diverse team to deliver 99.99999% uptime while eliminating human error. Jay also serves as a member of the Big Data Certificate Program Advisory Board since 1998. 
                            <span style={{ display: (showingJay ? 'block' : 'none') }}>
                            <br />
                            At JPHP, Jay supports the team through his expert advice on data science and management, finance as well as market strategies. Biologically older brother of the founder Dr. Munshi and professionally his mentor and guide, Jay has been one the thrust behind the development of JPHB. He continues to guide the entrepreneurial effort and operational framework at JPHB. 
                            </span>
                            <a href="javascript:void(0);" onClick={() => this.setState({ showingJay: !showingJay })} style={{ display: (showingJay ? 'none' : 'block'), color: "#2d419a", paddingTop: "10px"}}>Read More...</a>
                            <a href="javascript:void(0);" onClick={() => this.setState({ showingJay: !showingJay })} style={{ display: (showingJay ? 'block' : 'none'), color: "#2d419a", paddingTop: "10px"}}>Hide...</a>
                            </CardText>
                            <a href="https://www.linkedin.com/in/jaymunshi/" style={{color: "#2d419a"}} target="_blank">Linkedin &gt;</a>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} sm={{span: 6}}>
                    <Card>
                        <CardImg top src={sampathhs} className="mx-auto d-block about-card-img-top" alt="Card image cap" />
                        <CardBody>
                        <CardTitle tag="h5">Walajabad Sampath</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted"></CardSubtitle>
                        <hr />
                        <CardText>
                        Prof. W.S. Sampath is a professor of Mechanical Engineering at Colorado State University and has been an expert in fields of thin film photovoltaics, materials engineering, semi-conductor materials science, and engineering applications of solid-state physics since 1985. He was also one of the founders of multi-million-dollar solar panel manufacturer Abound Solar. Prof. Sampath has been one of the leading researchers in thin film photovoltaics and is driven by his vision to make solar electricity a leading source of energy for the United States and the world. He has led pioneering efforts in establishment of CdTe-based photovoltaics as a commercially successful technology. He was one of the first researchers to demonstrate feasibility of CdTe-based photovoltaics for utility scale field application. 
                        <span style={{ display: (showingSampath ? 'block' : 'none') }}>
                        <br />
                        Prof. Sampath has been the academic advisor for the member of the founding team. At JPHB, Prof. Sampath has agreed to guide the technological developments for an ecologically and economically self-sustained solar energy solution network. His expertise and passion for sustainable energy is a critical foundational building block for the success of JPHB as an enterprise.  
                        </span>
                        <a href="javascript:void(0);" onClick={() => this.setState({ showingSampath: !showingSampath })} style={{ display: (showingSampath ? 'none' : 'block'), color: "#2d419a", paddingTop: "10px"}}>Read More...</a>
                        <a href="javascript:void(0);" onClick={() => this.setState({ showingSampath: !showingSampath })} style={{ display: (showingSampath ? 'block' : 'none'), color: "#2d419a", paddingTop: "10px"}}>Hide...</a>
                        </CardText>
                        <a href="https://www.engr.colostate.edu/me/faculty/dr-walajabad-s-sampath/" style={{color: "#2d419a"}} target="_blank">CSU Profile &gt;</a>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
                <Row className="rowAbout">
                    <Col className="visionContainer"  sm="12" md={{ size: 6 }}>
                        <br />
                    <div>
                        <h2>Partners
                        </h2>
                        <hr className="visionHr" />
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={{span: 4}} style={{textAlign: 'center'}}>
                        <br />
                        <img src={tfdLogo} style={{display: 'inline-block'}} className="" alt="about_us" />
                    </Col>
                    <Col xs={12} sm={{span: 8}}>
                        TFD’s mission is to help solve Thin Film electro-optical issues and speed up transfer from pre-production to mass production either in the U.S. or Asia.
                        <br /><br />
                        <a style={{color: 'blue'}} href="https://tfdinc.com/">https://tfdinc.com/</a>
                    </Col>
                </Row>
                <br /><hr /><br />
                <Row>
                    <Col xs={12} sm={{span: 4}} style={{textAlign: 'center'}}>
                        <img src={mdgreenenergy} style={{display: 'inline-block'}} className="" alt="about_us" />
                    </Col>
                    <Col xs={12} sm={{span: 8}}>
                    Turning environment sustenance into an investment in business growth with customer focus.
                    <br /><br />
                    A renewable energy and energy efficiency company that believes in bringing value to business through streamlining of processes, new technologies and a thorough understanding of market needs.
                    <br /><br />
                        <a style={{color: 'blue'}} href="https://mdgreenenergy.com/index.html">https://mdgreenenergy.com</a>
                    </Col>
                </Row>
                <br /><br />
            </Container>
        );
    }
}

export default About;