import React from 'react';
import "./css/Investors.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Container, Row, Col } from 'react-bootstrap';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';

import InvRes from './assets/InvRes.jpg';

const Mailto = ({ email, subject, body, children }) => {
    return (
      <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
    );
  };

function Investors() {
    window.scrollTo(0,0);
    return (
        <React.Fragment>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Investor Resources</Breadcrumb.Item>
                </Breadcrumb>
                <hr />
                <Row>
                    <Col>
                    <div className="GreyRounded">
                    Interested in a detailed <span style={{fontWeight: 500}}>pitchdeck</span> from JPHB? Please contact <Mailto email="amit@jphb.us" subject="Investor Pitchdeck Request" body="" style="color: blue;"><span style={{color: 'blue'}}>amit@jphb.us</span></Mailto> to request more information.
                    </div>
                    </Col>
                </Row>  
                <br />
                <Row>
                    <Col>
                    <h2 style={{paddingBottom: '10px'}}><span className="underline underlineBlue">Timeline</span></h2>
                    JPHB is a company created by professionals with a vision to take disruptive innovations
                    from the laboratory to society. These innovations are for commercial installations and PPA using currently available solar panels, and replacing them with in-house manufactured high-efficiency solar panels in the near future.

                    </Col>
                </Row>
                <Row>
                <Col>
                    <Timeline lineColor={'#2d419a'}>
                        <TimelineItem
                            key="001"
                            dateText="Milestone 1"
                            style={{ color: '#f9a11c' }}
                            dateInnerStyle={{ background: '#2d419a', color: 'white' }}
                        >
                            <h3>Entrypoint Evaluation</h3>
                            <br />
                            <p>
                            <ul>
                                <li>Lowest cost of energy generation with demonstrated power output advantage. </li>
                                <li>Power purchase agreements (PPA) utilizing existing real estate with small to medium businesses. </li>
                                <li>Community scale PV installations.</li>
                            </ul>
                            </p>
                        </TimelineItem>
                        <TimelineItem
                            key="002"
                            dateText="Milestone 2"
                            style={{ color: '#f9a11c' }}
                            dateInnerStyle={{ background: '#2d419a', color: 'white' }}
                        >
                            <h3>Revenue Streams</h3>
                            <br />
                            <p>
                            <ul>
                                <li>Small to medium scale PPA eventually leading into MW-scale PPA with utilities. </li>
                                <li>Shortest Return on Investment (ROI) projections – reliable long term revenue stream </li>
                            </ul>
                            </p>
                        </TimelineItem>
                        <TimelineItem
                            key="003"
                            dateText="Milestone 3"
                            style={{ color: '#f9a11c' }}
                            dateInnerStyle={{ background: '#2d419a', color: 'white' }}
                        >
                            <h3>IP Generation</h3>
                            <br />
                            <p>
                            <ul>
                                <li>Technologies patented and exclusive option to JPHB.</li>
                                <li>Disruptive innovation in module installation method with unprecedented power generation advantage. </li>
                                <li>Key improvements in device architecture and manufacturing methodology. </li>
                                <li>Reduced time of manufacturing, complexity, and process steps for output comparable to the current state-of-the-art.</li>
                                <li>Dopant free devices for simpler process control, longer filed longevity. </li>
                            </ul>
                            </p>
                        </TimelineItem>
                        <TimelineItem
                            key="004"
                            dateText="Milestone 4"
                            style={{ color: '#f9a11c' }}
                            dateInnerStyle={{ background: '#2d419a', color: 'white' }}
                        >
                            <h3>Applications</h3>
                            <br />
                            <p>
                            <ul>
                                <li>Rooftop and utility scale installations for PPA.</li>
                                <li>Ultra-thin substrate high power to weight ratio panels customized for space, military, and disaster relief. </li>
                                <li>Cutting-edge module architecture to improve power output by over 2x with marginal increase in manufacturing cost for field installations. </li>
                            </ul>
                            </p>
                        </TimelineItem>
                        <TimelineItem
                            key="005"
                            dateText="Milestone 5"
                            style={{ color: '#f9a11c' }}
                            dateInnerStyle={{ background: '#2d419a', color: 'white' }}
                        >
                            <h3>Peak Functionality</h3>
                            <br />
                            <p>
                            <ul>
                                <li>A two-prong approach of PPA and manufacturing for long-term self-sustained for-profit model.</li>
                                <li>PPAs provide reliable, ongoing revenue stream. </li>
                                <li>Module development and manufacturing to feed the PPA model with high-quality low-cost panels.</li>
                                <li>Light weight high power-weight ratio panels manufacturing to further improve the commercial advantage through specialty applications. </li>
                            </ul>
                            </p>
                        </TimelineItem>
                        <TimelineItem
                            key="005"
                            dateText="Long Term Projections"
                            style={{ color: '#f9a11c' }}
                            dateInnerStyle={{ background: '#2d419a', color: 'white' }}
                        >
                            <h3>Enter Sustainable Food-Energy-Water Nexus</h3>
                        </TimelineItem>
                        </Timeline>
                    </Col>
                </Row>
            </Container>
            <br />
            <br />
        </React.Fragment>
    );
}

export default Investors;