import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/Footer.css';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { Button } from 'react-bootstrap';

import logo from '../assets/Logo V2.jpg';

const Mailto = ({ email, subject, body, children }) => {
    return (
      <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
    );
  };

export const Footer = () => (
    <React.Fragment>
        <div style={{backgroundColor: "rgba(232, 232, 232, 1)"}}>
        <hr />
        <Container>
            <Row className="frow">
                <Col className="text-center">
                <a href="/#/" className="flink">Home</a><span> &#183; </span>
                <a href="/#/Investors" className="flink">Investor Resources</a><span> &#183; </span>
                <a href="/#/About" className="flink">About Us</a><span> &#183; </span>
                <a href="/#/Contact" className="flink">Contact Us</a><br /> <br />
                    {/*<a href="#" className="flink">Home</a><span> &#183; </span>
                    <a href="#" className="flink">Investor Resources</a><span> &#183; </span>
                    <a href="#" className="flink">Demonstrated Experince</a> <br /> <br />
                    <a href="#" className="flink">Education</a><span> &#183; </span>
                    <a href="#" className="flink">About Us</a><span> &#183; </span>
                    <a href="#" className="flink">Contact Us</a><br /> <br />
                    <a href="#" class="fa fa-facebook" style={{paddingTop: "0px", paddingLeft: "14px", paddingRight: "12px", fontSize: "20px"}}></a><span> &#183; </span>
                    <a href="#" class="fa fa-instagram" style={{paddingTop: "0px", paddingLeft: "14px", paddingRight: "8px", fontSize: "20px"}}></a>
                    {/*Subscribe to our newsletter <br />
                    Research, news, and product information <br /><br />
                    <InputGroup>
                        <Input placeholder="Email" />
                        <InputGroupAddon addonType="append"><Button color="secondary">Subscribe</Button></InputGroupAddon>
                    </InputGroup> <br /> */}
                </Col>
            </Row>
            <hr />
            <Row className="footerrow">
                <Col className="col-md-6 col-sm-12 col-12">
                <p className="pfoot">&copy;{new Date().getFullYear} JPHB SOLUTIONS, Ltd. All Rights Reserved</p>
                {/*</Col>
                <Col className="col-md-6 col-sm-12 col-12">
                <div style={{textAlign: "right"}}><a href="#" className="footera">Contacts</a> | <a href="#" className="footera">Terms</a> | <a href="#" className="footera">Privacy</a> | <a href="#" className="footera">FAQ</a> | <a href="#" className="footera">Trademarks</a></div>
                */}
                </Col>
            </Row>
        </Container>
        </div>
    </React.Fragment>
)