import React from 'react';
import '../css/NavigationBar.scss';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { Container, Row, Col } from 'react-bootstrap';

import logo from '../assets/Logo V2.jpg';
import newlogo from '../assets/felixlogo.png';

function NavigationBar() {
    return(
        <React.Fragment>
            <Container>
                <Row>
                    <Col md={{ span: 12, offset: 0 }}>
                        <Navbar collapseOnSelect expand="lg">
                        <Navbar.Brand href="/#/"><img src={newlogo} className="NavLogo" />{/*<span className="logoText">JPHB</span>*/}</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav" className="flex-column align-items-end">
                            <Nav id="examples" style={{fontSize: "16px", paddingBottom: "10px"}} className="d-none d-lg-flex">
                                {/*<Nav.Link href="#" className="NavLink example"><span className="hover hover-1">Education</span></Nav.Link></Nav>*/}
                                {/*<Nav.Link href="/#/Compadv" className="NavLink example"><span className="hover hover-1">Competitive Advantage</span></Nav.Link>*/}
                                {/*<a href="#" class="fa fa-facebook" style={{paddingTop: "11px", paddingLeft: "10px", paddingRight: "8px"}}></a>
                                <a href="#" class="fa fa-instagram" style={{paddingTop: "12px", paddingLeft: "12px", paddingRight: "8px"}}></a>*/}
                            </Nav>
                            <Nav id="examples">
                                <Nav.Link href="/#/" className="NavLink example"><span className="hover hover-1">Home</span></Nav.Link>
                                <Nav.Link href="#/Investors" className="NavLink example"><span className="hover hover-1">Investor Resources</span></Nav.Link>
                                <Nav.Link href="/#/Compadv" className="NavLink example"><span className="hover hover-1">Competitive Advantage</span></Nav.Link>
                                <Nav.Link href="#/About" className="NavLink example"><span className="hover hover-1">About Us</span></Nav.Link>
                                <Nav.Link href="#/Contact" className="NavLink example"><span className="hover hover-1">Contact Us</span></Nav.Link>
                                {/*<Nav.Link href="/#/CompAdv" className="NavLink example  d-block d-lg-none"><span className="hover hover-1">Competitive Advantage</span></Nav.Link>*/}
                                {/*<Nav.Link href="#" className="NavLink example d-block d-lg-none" style={{float: "left"}}><span className="hover hover-1">Education</span></Nav.Link>
                                <a href="#" class="fa fa-search" style={{paddingTop: "16px", paddingLeft: "20px", paddingRight: "8px", fontSize: "21px"}}></a>*/}
                            </Nav>
                        </Navbar.Collapse>
                        </Navbar>
                    </Col>
                </Row>
            </Container>
        <hr style={{marginBottom: "10px", marginTop: "0px"}} />
        </React.Fragment>
    );
}

export default NavigationBar;