import React from 'react';
import "./css/Home.css";
import "./css/NavigationBar.scss";
import { HomeCarousel } from './components/HomeCarousel';
import { Container, Row, Col } from 'react-bootstrap';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { Button } from 'react-bootstrap';
import {Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle} from 'reactstrap';
import { HashLink } from "react-router-hash-link";

import plug from "./assets/plug.png";
import globe from "./assets/globe.png";
import panel from "./assets/solar-energy.png";

import slogan from "./assets/slogan.png";

import slide01 from './assets/sunrisenog.jpg';
import slide02 from './assets/solargrid.jpg';
import slide03 from './assets/cadnium2.jpg';
import slide04 from './assets/Scalability.png';
import slide05 from './assets/Research and Development_home.png';
import slide06 from './assets/ourpanel.png';

function Home() {
    const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -20; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}
    return (
        <React.Fragment>
            <HomeCarousel />
            {/*<Container>
                <Row id="examples">
                    <Col className="text-center example" xs={12} sm={4}>
                    <img src={plug} className="icon"/>
                    <h4><span className="hover hover-3">Renewable Energy</span></h4>
                    </Col>
                    <Col className="text-center example" xs={12} sm={4}>
                    <img src={globe} className="icon"/>
                    <h4><span className="hover hover-3">Global Mission</span></h4>
                    </Col>
                    <Col className="text-center example" xs={12} sm={4}>
                    <img src={panel} className="icon"/>
                    <h4><span className="hover hover-3">Solar Education</span></h4>
                    </Col>
                </Row>
            </Container>*/}
            <Container className="contPad">
                <Row>
                    <Col className="sloganmobile">
                    <h2><span className="underline underlineBlue">What we do</span></h2><br />
                    We are committed to providing innovative solar energy solutions that are economically viable and completely self-sustained. Financial sustainability is key to establishing solar energy as the obvious choice. Our patent-pending cutting-edge energy solutions provide at least 50% greater power output for marginal increase in capital expenditure.
                    </Col>
                </Row>
                <br /><br />
                <Row>
                    <Col className="sloganmobile">
                    <h2><span className="underline underlineYellow">Who we are</span></h2><br />
                    We are a team of dedicated scientists and engineers that have invested a major part of our lives understanding solar energy and are united in the goal to make it a dominant means to power the world. Our team of experts include engineers, physicist, materials scientists, and business strategists. Our combined expertise and experience of over 50 years brings together the most commercially viable energy solutions for residential, community, distributed, and utility scale applications.  
                    </Col>
                </Row>
                <br /><br />
                <Row>
                    <Col className="sloganmobile">
                    <h2><a href="/#/Compadv" className="underline underlineBlue">Our Competitive Advantage</a></h2><br />
                    <div className="comptext">Disruptive CleanTech solutions with unprecedented commercial advantage. Our superpower...</div><br /><br />
                    <div  style={{textAlign: 'center'}}>
                    <img src={slogan} style={{display: 'inline-block'}} className="sloganImg" alt="about_us" />
                    </div>
                    {/*<div className="compTagline"><i>A strong appetite to simplify solutions!</i></div>*/}
                    <br />
                    </Col>
                </Row>
                <br /><br />
                <Row>
                    <Col>
                        <div class="image-block-container right-overlap">
                        <img src={slide02} className="content-img" alt="about_us" />
                        <div className="outer">
                            <div class="text-block" style={{borderLeft: '3px solid #f9a11c'}}>
                                <HashLink style={{color: 'black'}} to="/CompAdv#install" scroll={el => scrollWithOffset(el)}><h2><span className="underline underlineYellow">Grid Integration</span></h2></HashLink><br/>
                                At JPHB, we have optimized the installation process for photovoltaic panels. Our proprietary installation process gives over 1.6x additional power output for marginal to no increase in cost of installation per watt. While the US PV industry is looking for marginal improvements in power output, our approach provides an excellent opportunity to give wings to the US CleanTech industry.
                                <br /><br />
                                <ul>
                                    <li>Proof-of-concept prototype installed and tested in field conditions over several weeks.</li>
                                    <li>Average additional power output of about 1.6x recorded over a 21-day period (under cloudy and sunny weather conditions).</li>
                                    <li>Estimates suggest the advantage may be extended to over 2x with optimization. </li>
                                </ul>
                                <br />  
                                <HashLink className="scrollLink" to="/CompAdv#install" scroll={el => scrollWithOffset(el)}>Learn More &gt;</HashLink>
                                <br /><br />
                            </div>
                        </div>
                        </div>
                        <div class="image-block-container right-overlap" style={{maxHeight: '1100px'}}>
                        <img src={slide05} className="content-img" alt="about_us" />
                        <div className="outerWhite">
                            <div class="text-block">
                                <HashLink style={{color: 'black'}} to="/CompAdv#research" scroll={el => scrollWithOffset(el)}><h2><span className="underline underlineBlue">Research and Development</span></h2></HashLink><br/>
                                At the heart of JPHB research team are experienced scientists and engineers with combined experience of over 50 years pursuing cutting-edge photovoltaics device research. The founder has been either a major contributor or the team lead for introduction of at least 5 technological innovations in the field. 
                                <br /><br />
                                <ul>
                                    <li>The research team has improved academic solar cell record efficiency from about 11% to over 20% in the past 10 years and has a clear roadmap for development of efficiencies over 30%. </li>
                                    <li>Realizing this would mean efficiency of solar panels comparable to space solar technology at a price tag of terrestrial panels. </li>
                                    <li>The R&amp;D team is currently validating solar cells with a field life of over 50 years using scalable and commercially viable processes.</li>
                                </ul>
                                <br /><HashLink className="scrollLink" to="/CompAdv#research" scroll={el => scrollWithOffset(el)}>Learn More &gt;</HashLink>
                                <br /><br />
                            </div>
                        </div>
                        </div>
                    <div class="image-block-container right-overlap" style={{maxHeight: '1000px'}}>
                        <img src={slide04} className="content-img" alt="about_us" />
                        <div className="outer">
                            <div class="text-block"  style={{borderLeft: '3px solid #f9a11c'}}>
                                <HashLink style={{color: 'black'}} to="/CompAdv#scalability" scroll={el => scrollWithOffset(el)}><h2><span className="underline underlineYellow">Scalability</span></h2></HashLink><br/>
                                Every JPHB innovation and development maintains a clear line of sight to scalability. The core team maintains diligent records of all key input and performance parameters. The demonstrated efficiencies and improvements are statistically significant. 
                                <br /><br />
                                <ul>
                                    <li>Process development and optimization is based on fabrication and analysis of over 300,000 small area solar cells.</li>
                                    <li>Early-stage development of mini-modules with high efficiencies demonstrated.</li>
                                    <li>Research team with demonstrated expertise in process development and control; currently working on employing machine learning in R&D as well as process control. </li>
                                </ul>
                                <br /><HashLink className="scrollLink" to="/CompAdv#scalability" scroll={el => scrollWithOffset(el)}>Learn More &gt;</HashLink>
                                <br /><br />
                            </div>
                        </div>
                        </div>
                        <div class="image-block-container right-overlap" style={{maxHeight: '1000px'}}>
                        <img src={slide06} className="content-img" alt="about_us" />
                        <div className="outerWhite">
                            <div class="text-block" name="install">
                                <HashLink style={{color: 'black'}} to="/CompAdv#research" scroll={el => scrollWithOffset(el)}><h2><span className="underline underlineBlue">Specialty Applications</span></h2></HashLink><br/>
                                The JPHB team along with their technology partners are committed to expanding the horizons of thin film solar panel applications. Working closely with TFD Inc., the JPHB team has demonstrated solar cells and modules on thin to ultra-thin glass. This development is aimed at utilizing high power-density solar modules for space, military, and emergency/disaster relief applications. 
                                <br /><br />
                                <ul>
                                    <li>Thin film solar cells fabricated on less than 1 mm thin glass for excellent power-to-weight ratio for specialty applications. </li>
                                    <li>Fast throughput and lower than current state-of-the-art cost for easy and economical transportation.</li>
                                    <li>Thin and high-quality glass with index-match anti-reflection coating to maximize power output.</li>
                                </ul>
                                <br /><HashLink className="scrollLink" to="/CompAdv#research" scroll={el => scrollWithOffset(el)}>Learn More &gt;</HashLink>
                                <br /><br />
                            </div>
                        </div>
                        </div>
                    </Col>
                </Row>
                <br /><br />
                <Row>
                    <Col>
                    <div style={{textAlign: 'center'}}>
                        <h3 style={{padding: "0px"}}>News & Insights</h3>
                        <hr />
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4} style={{paddingBottom: "20px"}}>
                    <Card>
                        <CardImg top src={slide01} alt="Card image cap" style={{maxWidth: "360px", maxHeight: "200px"}}/>
                        <CardBody>
                        <CardTitle tag="h5">Event One</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">January 1st, 2021</CardSubtitle>
                        <CardText>Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>
                        <a href="#" style={{color: "#2d419a"}}>Learn More &gt;</a>
                        </CardBody>
                    </Card>
                    </Col>
                    <Col sm={4} style={{paddingBottom: "20px"}}>
                    <Card>
                        <CardImg top src={slide02} alt="Card image cap" style={{maxWidth: "360px", maxHeight: "200px"}}/>
                        <CardBody>
                        <CardTitle tag="h5">Event Two</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">January 1st, 2021</CardSubtitle>
                        <CardText>Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>
                        <a href="#" style={{color: "#2d419a"}}>Learn More &gt;</a>
                        </CardBody>
                    </Card>
                    </Col>
                    <Col sm={4} style={{paddingBottom: "20px"}}>
                    <Card>
                        <CardImg top src={slide03} alt="Card image cap" style={{maxWidth: "360px", maxHeight: "200px"}}/>
                        <CardBody>
                        <CardTitle tag="h5">Event Three</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">January 1st, 2021</CardSubtitle>
                        <CardText>Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>
                        <a href="#" style={{color: "#2d419a"}}>Learn More &gt;</a>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
                <br />
                <Container className="newslettercontainer">
                    <Row>
                        <Col>
                        <div style={{textAlign: 'center'}}>
                            <h2>Get updates on JPHB through our newsletter.</h2><br />
                            <InputGroup className="emailbox">
                                <Input placeholder="Email" />
                                <InputGroupAddon addonType="append"><Button color="secondary">Subscribe</Button></InputGroupAddon>
                            </InputGroup> <br />
                        </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </React.Fragment>
    );
}

export default Home;